import md5 from 'js-md5'
var ApiSign={
    //获取时间戳
    GetTimeStamp(){
        var timestamp=parseInt(Date.now());
        return timestamp;
    },
    //获取Nonce
    GetNonce(){
        var str = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890";
        var arr = str.split("");
        var result = "";
        for(var i=0;i<4;i++)
        {
            var n = Math.floor(Math.random() * arr.length);
            result += arr[n];
        }
        return result;
    },
    GetSign(data,appid,secret,timestamp,nonce){
        var sign_str=data+appid+timestamp+nonce+secret;
        var signature = md5(sign_str + '');
        return signature;
    }
}
export default ApiSign;