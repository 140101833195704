import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import http from './utils/http'
import api from './utils/api'
import utils from './utils/utils'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
var Emitter = require('tiny-emitter');
var emitter = new Emitter();

import './assets/css/base.css'
import './assets/css/style.css'
import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'

const app = createApp(App);


app.config.globalProperties.$http = http;
app.config.globalProperties.utils = utils;
app.config.globalProperties.api = api;
app.config.globalProperties.emitter = emitter;

app.use(store).use(router).use(ElementPlus).mount("#app");

app.use(Viewer)
