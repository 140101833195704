<template>
  <div class="container">
    <Header></Header>
    <router-view v-slot="{ Component }">
      <keep-alive>
        <component :is="Component" :key="$route.name" v-if="$route.meta.keepAlive" />
      </keep-alive>
      <component :is="Component" :key="$route.name" v-if="!$route.meta.keepAlive" />
    </router-view>

  </div>
</template>

<script setup>
import Header from '../../components/Common/Header.vue'
import { useRoute } from 'vue-router';
const $route = useRoute();

</script>
