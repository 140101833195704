<template>
  <div>
    <div class="com_header">
      <div class="com_header_box">
        <router-link :to="{ name: 'home' }" class="l_logo"><img src="../../assets/images/logo.png" /></router-link>
        <div class="r_menu">
          <router-link :to="{ name: 'home' }" :class="[$route.meta.nav == 'home' ? 'curr' : '']">首页</router-link>
          <router-link :to="{ name: 'materiallist' }"
            :class="[$route.meta.nav == 'cmflist' ? 'curr' : '']">材料库</router-link>
          <router-link :to="{ name: 'courselist' }"
            :class="[$route.meta.nav == 'course' ? 'curr' : '']">材料云课堂</router-link>
        </div>
        <div class="r_login">
          <template v-if="$route.name == 'home'">
            <div class="key_box">
              <input class="input" v-model="data.keyword" placeholder="请输入关键字" />
              <div class="search" @click="goMaterialList">
                <img src="../../assets/images/search.png" />
              </div>
            </div>
          </template>

          <template v-if="data.user_info == ''">
            <router-link :to="{ name: 'login' }" class="btn">
              <img class="icon" src="../../assets/images/persion.png" />
              <div class="text">登录</div>
            </router-link>
          </template>
          <template v-if="data.user_info != ''">
            <div class="mycenter">
              <div class="box">
                <img class="icon" src="../../assets/images/header.png" />
                <div class="text">{{ data.user_info.real_name ? data.user_info.real_name : data.user_info.tel }}</div>
              </div>
              <div class="drop">
                <router-link :to="{ name: 'message' }" class="drop_item">
                  <div class="tips">个人信息</div>
                </router-link>
                <router-link :to="{ name: 'collect' }" class="drop_item">
                  <div class="tips">我的收藏</div>
                </router-link>
                <a href="javascript:void(0)" class="drop_item" @click="loginOut">
                  <div class="tips">退出登录</div>
                </a>
              </div>
            </div>
          </template>
          <div class="language">
              <router-link :to="{ name: 'home' }" class="curr">中</router-link>
              <router-link :to="{ name: 'Enhome' }">EN</router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="backup"
      v-if="$route.name != 'courselist' && $route.name != 'materiallist' && $route.name != 'home' && $route.name != 'login' && $route.name != 'collect'">
      <img src="../../assets/images/backup.png" @click="$router.go(-1);" />
    </div>
  </div>
</template>

<script setup>
import { reactive, getCurrentInstance } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { localStorage } from '../../utils/storage';
const $route = useRoute();
const $router = useRouter();
const _this = getCurrentInstance().appContext.config.globalProperties;

const data = reactive({
  user_info: '',
  keyword: $route.query.keyword,
});

function InitPage() {
  if (localStorage.get('user_info')) {
    data.user_info = localStorage.get('user_info');
  }
}

function goMaterialList() {
  $router.push({name:'materiallist', params:{ keyword :data.keyword}});
}


function loginOut() {
  localStorage.remove('user_info');
  window.location.reload();
  data.user_info = '';

  if($route.name == 'collect' || $route.name == 'message'){
    $router.push({ name: 'login'});
  }
}

_this.emitter.on('isLogin', () => {
  data.user_info = localStorage.get('user_info');
});


InitPage();

</script>

<style>
.backup {
  width: 1300px;
  margin: 26px auto 16px auto;
}

.backup img {
  width: 64px;
  height: 30px;
  cursor: pointer;
}
</style>
