import store from '../store'
import $ from 'jquery'
var utils={
    DefaultAvatar:'images/phto-d.png',
    DefaultImg:'https://m.xincailiao.com/backstage/skin/default/loadimg.gif',
    DefaultProImg:'http://m.xincailiao.com/upload/201906/06/2019060615421842290.png',
    DefaultComImg:'https://m.xincailiao.com/images/company_icon/com_logo.png',
    DefaultSupplyImg:'images/supply.png',
    APPID:'d6004606cbf44b26b85c7877fcdb5d59',
    GetDomain(){
        var is_test=false;
        if (is_test) return 'http://localhost:48782'
        else return 'http://ubobk.xincailiao.com';
    },
    HandleUrl(_url,_type){
        if (_url===undefined) return '';
        if (_url===null) return '';
        if (_url.indexOf('http')!=0){
          if (_type){
            return 'http://m.xincailiao.com' + _url;
          }else{
            return this.GetDomain() + _url;
          }
        }
        return _url;
    },
    //_type:0头像，1封面 2项目 3公司 4供应
    HandleImg(_img_url,_nodefault,_type){
        if (_img_url!==undefined&&_img_url!=='') return this.HandleUrl(_img_url);
        else{
            if (_nodefault) return "";
            else{
                if (_type===1){
                    return this.DefaultImg;
                }else if (_type===2){
                    return this.DefaultProImg;
                }else if (_type===3){
                    return this.DefaultComImg;
                }else if (_type==4){
                    return this.DefaultSupplyImg;
                }else{
                    return this.DefaultAvatar;
                }
            }
        }
    },
    HandleContentImg(_content,_type){
        if (!_content) return '';
        var reg=/src="(\/upload\/[^\"]*)"/g;
        if (!_type){
          _content = _content.replace(reg, 'src="http://ubobk.xincailiao.com$1"');
        }else{
          _content = _content.replace(reg, 'src="https://m.xincailiao.com$1"');
        }
        return _content;
    },
    GetFileSize(_size){
        var strSize="";
        if (_size>(1024*1024)){
            strSize=(_size/1024/1024).toFixed(2)+"M";
        }else{
            strSize=(_size/1024).toFixed(2)+"KB";
        }
        return strSize;
    },
    GetCurrentTime(){
        var date = new Date();
        var date_str = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate() + ' ' + date.getHours() + ':' + date.getMinutes();
        return date_str;
    },
    GetStatusStr(_status){
        if (_status==0){
            return '正常';
        }else if (_status==1){
            return '待审核';
        }else if (_status==2){
            return '不通过'
        }else{
            return '未知';
        }
    },
    GetRamId(){
        return (Math.random() * 10000000).toString(16).substr(0, 4) + '-' + (new Date()).getTime() + '-' + Math.random().toString().substr(2, 5);
    },
    setNav(_nav){
        store.dispatch('Common/setNav',_nav);
    },
    Logout(){
        store.dispatch('User/setToken','');
    },
    //下拉自动加载 参数：执行函数 偏移量
    AddLoadMoreListener(_callback,_offset){
        var offset = 10;
        if(_offset != undefined && _offset > 0){
            offset = _offset;
        }
        
        if(typeof _callback === 'function'){
            var fun =function () { 
                //判断是否滚动到底部
                var scrollTop = document.documentElement.scrollTop;
                if(scrollTop === 0){
                    scrollTop = $(document).scrollTop();
                }
                if(scrollTop + window.innerHeight + offset >= $(document).height()){
                    _callback();
                }
            }
            //注册scroll事件并监听
            window.addEventListener('scroll',fun);
            return fun;
        }
    },
    IsNormalScreen(){
        if (window.innerWidth&&window.innerWidth>0&&window.innerWidth<=1920) return true;
        return false;
    }
}

export default utils;  