<template>
  <div class="container">
    <HeaderEn></HeaderEn>
    <router-view v-slot="{ Component }">
      <keep-alive>
        <component :is="Component" :key="$route.name" v-if="$route.meta.keepAlive" />
      </keep-alive>
      <component :is="Component" :key="$route.name" v-if="!$route.meta.keepAlive" />
    </router-view>

  </div>
</template>

<script setup>
import HeaderEn from '../../components/Common/HeaderEn.vue'
import { useRoute } from 'vue-router';
const $route = useRoute();

</script>
