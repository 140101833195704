import {
  createRouter,
  createWebHistory
} from "vue-router";
import MainLayout from "../views/Layout/MainLayout.vue";
import EnMainLayout from "../views/Layout/EnMainLayout.vue";
import {
  localStorage
} from '../utils/storage.js';

const routes = [{
  path: "/",
  component: MainLayout,
  children: [{
      path: '',
      name: 'home',
      component: () =>
        import('../views/Home.vue'),
      meta: {
        nav: 'home',
        needRefresh: true
      }
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('../views/Persion/Login.vue'),
      meta: {
        nav: 'home'
      }
    },
    {
      path: '/message',
      name: 'message',
      component: () => import('../views/Persion/Message.vue'),
      meta: {
        nav: 'home'
      }
    },
    {
      path: '/collect',
      name: 'collect',
      component: () => import('../views/Persion/Collect.vue'),
      meta: {
        nav: 'home'
      }
    },
    {
      path: '/materiallist/:keyword?',
      name: 'materiallist',
      component: () => import('../views/Material/List.vue'),
      meta: {
        nav: 'cmflist',
        keepAlive: true
      }
    },
    {
      path: '/materialdetail/:id',
      name: 'materialdetail',
      component: () => import('../views/Material/Detail.vue'),
      meta: {
        nav: 'cmflist'
      }
    },
    {
      path: '/anlidetail/:id',
      name: 'anlidetail',
      component: () => import('../views/Anli/Detail.vue'),
      meta: {
        nav: 'cmflist'
      }
    },
    {
      path: '/courselist',
      name: 'courselist',
      component: () => import('../views/Course/List.vue'),
      meta: {
        nav: 'course',
        keepAlive: true
      }
    },
    {
      path: '/coursedetail/:id',
      name: 'coursedetail',
      component: () => import('../views/Course/Detail.vue'),
      meta: {
        nav: 'course'
      }
    },
    {
      path: '/vedio/:id/:index',
      name: 'vediodetail',
      component: () => import('../views/Course/Vedio.vue'),
      meta: {
        nav: 'course'
      }
    },
  ]
},
{
  path: "/En",
  component: EnMainLayout,
  children: [{
    path: '',
    name: 'Enhome',
    component: () =>
      import('../views/En/EnHome.vue'),
    meta: {
      nav: 'enhome',
      needRefresh: true
    }
  },
  {
    path: '/En/materiallist/:keyword?',
    name: 'enmateriallist',
    component: () => import('../views/En/Material/List.vue'),
    meta: {
      nav: 'encmflist',
      keepAlive: true
    }
  }, {
    path: '/En/materialdetail/:id',
    name: 'enmaterialdetail',
    component: () => import('../views/En/Material/Detail.vue'),
    meta: {
      nav: 'encmflist'
    }
  },{
    path: '/En/anlidetail/:id',
    name: 'enanlidetail',
    component: () => import('../views/En/Anli/Detail.vue'),
    meta: {
      nav: 'encmflist'
    }
  },{
    path: '/En/courselist',
    name: 'encourselist',
    component: () => import('../views/En/Course/List.vue'),
    meta: {
      nav: 'encourse',
      keepAlive: true
    }
  },
  {
    path: '/En/coursedetail/:id',
    name: 'encoursedetail',
    component: () => import('../views/En/Course/Detail.vue'),
    meta: {
      nav: 'course'
    }
  },
  {
    path: '/En/vedio/:id/:index',
    name: 'envediodetail',
    component: () => import('../views/En/Course/Vedio.vue'),
    meta: {
      nav: 'course'
    }
  },]
}
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  let is_login = to.name == 'collect' || to.name == 'message' ? true : false;
  if (is_login) {
    if (!localStorage.get('user_info')) {
      next({
        name: "login"
      })
    } else {
      next()
    }
  } else {
    next()
  }
});

export default router;