<template>
  <div>
    <router-view />
  </div>
</template>

<script>

</script>

<style>
#app{
  min-width: 1300px;
}
</style>
