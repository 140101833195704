export const localStorage = {
  //存储
  set(key, value,seconds) {
    const date = new Date().getTime()
    window.localStorage.setItem(key, JSON.stringify({ value, date, seconds }))
  },
  //取出数据
  get(key) {
    console.log(window.localStorage);
    const date = new Date().getTime()
    const item = window.localStorage.getItem(key) || ''
    if (item) {
      const { value, date: oldDate, seconds } = JSON.parse(item)
      console.log(date - oldDate, seconds);
      if (seconds>0 && (date - oldDate >= (seconds*1000))) {
        window.localStorage.removeItem(key)
        return null
      } else {
        return value
      }
    }
  },
  // 删除数据
  remove(key) {
    window.localStorage.removeItem(key)
  }
};


export const sessionStorage = {
  //存储
  set(key, value) {
    window.sessionStorage.setItem(key, JSON.stringify(value))
  },
  //取出数据
  get(key) {
    const value = window.sessionStorage.getItem(key)
    if (value && value != "undefined" && value != "null") {
      return JSON.parse(value)
    }
    return null
  },
  // 删除数据
  remove(key) {
    window.sessionStorage.removeItem(key)
  }
}
