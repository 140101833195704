/**
 * axios封装
 * 请求拦截、响应拦截、错误统一处理
 */
 import axios from 'axios';
 import store from '../store/index';
 import Qs from 'qs'
//  import { Message } from 'element-ui';
import ApiSign from './ApiSign';
 
 /** 
  * 提示函数 
  * 禁止点击蒙层、显示一秒后关闭
  */
 const tip = msg => {
    //  return Message.error(msg);
    return console.log(msg);
 }
 
 /** 
  * 跳转登录页
  * 携带当前页面路由，以期在登录页面完成登录后返回当前页面
  */
 const toLogin = () => {
     //utils.JumpLogin();
 }
 
 /** 
  * 请求失败后的错误统一处理 
  * @param {Number} status 请求失败的状态码
  */
 const errorHandle = (status, other) => {
     // 状态码判断
     switch (status) {
         // 401: 未登录状态，跳转登录页
         case 401:
             toLogin();
             break;
         // 403 token过期
         // 清除token并跳转登录页
         case 403:
             tip('登录过期，请重新登录');
             localStorage.removeItem('token');
             store.commit('loginSuccess', null);
             setTimeout(() => {
                 toLogin();
             }, 1000);
             break;
         // 404请求不存在
         case 404:
             tip('请求的资源不存在'); 
             break;
         default:
             console.log(other);
             tip('网络请求错误');
         }}

    const appid='36148752';
    const secret='obeuv2asv402v4kj';
 
 // 创建axios实例
 var instance = axios.create({    timeout: 1000 * 12});
 // 设置post请求头
 //instance.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
 /** 
  * 请求拦截器 
  * 每次请求前，如果存在token则在请求头中携带token 
  */ 
 instance.interceptors.request.use(    
     config => {        
         if (config.method==='post'&&!config.isfile){
            config.data=Qs.stringify(config.data);

            var timestamp=ApiSign.GetTimeStamp();
            var nonce=ApiSign.GetNonce();
            config.headers.appid=appid;
            config.headers.timestamp=timestamp;
            config.headers.nonce=nonce;
            var signature = ApiSign.GetSign(config.data,appid,secret,timestamp,nonce);
            config.headers.signature=signature;
        }
        config.headers.fromsys='ubo';
        return config;    
     },    
     error => Promise.error(error))
 
 // 响应拦截器
 instance.interceptors.response.use(    
     // 请求成功
     // 请求成功
    res => {
        if (res.status===200){
            return Promise.resolve(res);
        }else{
            return Promise.reject(res);
        }
        //return res.status === 200 ? Promise.resolve(res) : Promise.reject(res);
    },  
     // 请求失败
     error => {
         const { response } = error;
         if (response) {
             // 请求已发出，但是不在2xx的范围 
             errorHandle(response.status, response.data.message);
             return Promise.reject(response);
         } else {
             // 处理断网的情况
             // eg:请求超时或断网时，更新state的network状态
             // network状态在app.vue中控制着一个全局的断网提示组件的显示隐藏
             // 关于断网组件中的刷新重新获取数据，会在断网组件中说明
             if (!window.navigator.onLine) {
                store.commit('changeNetwork', false);
             } else {
                 return Promise.reject(error);
             }
         }
     });
 
 export default instance;